import React, { useState, useEffect } from "react"
import axios from "axios"

import { motion } from "framer-motion"

import formStyle from "./whiteForm.module.scss"
import { Link } from "gatsby"

import statement from "../Assets/privacyverklaring.pdf"

const variants = {
  pregnant: {
    width: "5em",
    boxShadow: "-50px 25px 30px rgba(0, 0, 0, 0.274)",
    marginRight: `-5em`,
    opacity: 0,
  },
  born: {
    width: "300px",
    marginRight: `15vw`,
    boxShadow: "-50px 25px 30px rgba(0, 0, 0, 0.274)",

    opacity: 1,
  },
  closed: {
    width: "5em",
    marginRight: "0vw",
    opacity: 1,
    boxShadow: "-35px 25px 30px rgba(0, 0, 0, 0.274)",
  },
  open: {
    width: "300px",
    marginRight: `0vw`,
    boxShadow: "-50px 25px 30px rgba(0, 0, 0, 0.274)",

    left: "-15em",
    opacity: 1,
  },
}

export default function WhiteForm({ loc }) {
  const [mailSent, setmailSent] = useState(false)
  const [error, seterror] = useState(null)
  const [email, setemail] = useState("")
  const [voornaam, setVoornaam] = useState("")
  const [achternaam, setAchternaam] = useState("")
  const [formStatus, setformStatus] = useState("pregnant")
  const [mobile, setmobile] = useState(false)
  const handleFormSubmit = e => {
    e.preventDefault()

    axios({
      method: "post",
      url: "https://brcg.nl/send-whitepaper",
      //url: "https://brcg.nl/api/blog/handleForm.php",
      headers: { "content-type": "application/json" },
      data: { email: email, voornaam: voornaam, achternaam: achternaam },
    })
      .then(result => {
        if (result.data.sent) {
          setmailSent(result.data.sent)
          seterror(false)
        } else {
          seterror(true)
        }
      })
      .catch(error => seterror({ error: error.message }))
  }

  useEffect(() => {
    setTimeout(() => {
      setformStatus("closed")
      // console.log(formStatus)
    }, 500)
    setTimeout(() => {
      setformStatus("open")
      // console.log(formStatus)
    }, 10000)
  }, [])

  return (
    <motion.div
      className={formStyle.container}
      initial={{ opacity: 0 }}
      variants={variants}
      animate={formStatus}
      whileHover={formStatus === "closed" ? { scale: 1.05 } : { scale: 1 }}
      transition={{
        delay: 0,
        type: "spring",
        stiffness: 45,
      }}
    >
      {formStatus !== "closed" && (
        <h5
          className={formStyle.closeButton}
          onClick={() =>
            setformStatus(formStatus === "closed" ? "open" : "closed")
          }
          onKeyDown={() =>
            setformStatus(formStatus === "closed" ? "open" : "closed")
          }
        >
          {formStatus === "closed" ? `+` : `-`}
        </h5>
      )}
      {formStatus === "closed" ? (
        <motion.h3
          className={formStyle.closed}
          onClick={() => setformStatus("open")}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.3,
            type: "tween",
          }}
        >
          whitepaper
        </motion.h3>
      ) : (
        <form
          enctype="multipart/form-data"
          method="POST"
          className={formStyle.form}
          // action = 'https://brcg.nl/blog'
        >
          <motion.label
            for="from"
            className={formStyle.label}
            initial={{ opacity: 0, marginTop: "-2em" }}
            animate={{ opacity: 1, marginTop: "-0em" }}
            transition={{
              // delay: 0.5,
              duration: 0.6,
            }}
          >
            <h2>
              <span>Geinteresseerd in</span>
              <br></br>
              <span>
                de <span style={{ color: "white" }}>whitepaper</span>
              </span>
              ?
            </h2>
          </motion.label>
          <div className={formStyle.naam}>
            <motion.input
              className={formStyle.voornaam}
              onChange={e => setVoornaam(e.target.value)}
              autoComplete="off"
              type="text"
              required
              id="from"
              name="from"
              placeholder="voornaam"
              initial={{ color: "rgb(0)", opacity: 0 }}
              animate={{ color: "rgb(1)", opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 2,
                type: "spring",
              }}
            />
            <motion.input
              className={formStyle.achternaam}
              onChange={e => setAchternaam(e.target.value)}
              autoComplete="off"
              type="text"
              required
              id="from"
              name="from"
              placeholder="achternaam"
              initial={{ color: "rgb(0)", opacity: 0 }}
              animate={{ color: "rgb(1)", opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 2,
                type: "spring",
              }}
            />
          </div>
          <div className={formStyle.ui}>
            <motion.input
              className={formStyle.input}
              onChange={e => setemail(e.target.value)}
              autoComplete="off"
              type="email"
              required
              id="from"
              name="from"
              placeholder="uw@email.nl"
              initial={{ color: "rgb(0)", opacity: 0 }}
              animate={{ color: "rgb(1)", opacity: 1 }}
              transition={{
                delay: 0.2,
                // // ease: "easeInOut",
                duration: 2,
                type: "spring",
                // stiffness: 0,
                // damping: 800,
              }}
            />
            <motion.input
              className={formStyle.submit}
              type="submit"
              value={mailSent ? "verzonden" : "ontvang"}
              onClick={e => handleFormSubmit(e)}
              whileTap={{ color: "green" }}
              // transition={{
              //   duration: 5,
              // }}
              style={mailSent && { backgroundColor: "green" }}
            />
          </div>
          <a
            href={statement}
            target="__blank"
            className={formStyle.privacyLink}
          >
            privacy statement
          </a>
          {/* <Link to="/privacy-statement" className={formStyle.privacyLink}>
            privacy statement
          </Link> */}
        </form>
      )}
      {/* {mailSent && <div>"verzonden!"</div>} */}
      {/* {error && <div>{error}</div>} */}
    </motion.div>
  )
}
