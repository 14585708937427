// deps
import React from "react"

// styling
import footerStyle from "./footer.module.scss"

// components
// import ListLinkFooter from "./listLinkFooter"
import wijzer from "../Assets/footer/wegwijzer.png"
import statement from "../Assets/privacyverklaring.pdf"

export default function Footer({ loc }) {
  return (
    <footer>
      {loc.pathname === "/" && (
        <div className={footerStyle.mottoContainer}>
          <q className={footerStyle.motto}>
            Ons motto is: Alleen ga je sneller, samen kom je verder
          </q>
        </div>
      )}

      <div className={footerStyle.wijzer}>
        <img src={wijzer} alt="wegwijzer"></img>
        <q>
          Business Resilience Consultancy Groep uw partner en wegwijzer voor het
          realiseren van veerkracht en weerbaarheid in uw organisatie.
        </q>
      </div>
      {/* <ul className={footerStyle.menu}>
        <ListLinkFooter
          loc={loc}
          to="/"
          pageName="wie zijn wij?"
        ></ListLinkFooter>
        <ListLinkFooter
          loc={loc}
          to="/partners"
          pageName="partners"
        ></ListLinkFooter>
        <ListLinkFooter
          loc={loc}
          to="/werkwijze"
          pageName="werkwijze"
        ></ListLinkFooter>
        <ListLinkFooter
          loc={loc}
          to="/gedragscode"
          pageName="Gedragscode"
        ></ListLinkFooter>
        <ListLinkFooter
          loc={loc}
          to="/contact"
          pageName="contact"
        ></ListLinkFooter>
      </ul> */}
      <div className={footerStyle.creditContainer}>
        <p className={footerStyle.credit}>
          © Business Resilience Concultancy Group
        </p>
        <a
          className={footerStyle.credit}
          href={statement}
          target="_blank"
          rel="noreferrer"
        >
          {`| privacy verklaring |`}
        </a>
        <p className={footerStyle.credit}>
          Website ontwikkeld door{" "}
          <a href="https://www.bartkipping.nl" target="_blank" rel="noreferrer">
            © Bart Kipping
          </a>
        </p>
      </div>
    </footer>
  )
}
