// deps
import React, { useEffect } from "react"
import { motion } from "framer-motion"

// styling
import linkStyle from "./listLink.module.scss"

// components
import { Link } from "gatsby"

const variants = {
  open: { opacity: "100%", height: 1, width: "100%" },
  closed: { opacity: "0%", height: 1, width: "0%" },
}

const history = [""]

export default function ListLink({location, pageName, to, data, loc, prev }, props) {
  let loca = loc.pathname

  const isOpen =
    loca === "/" && pageName === "wie zijn wij?"
      ? true
      : loca === `/${pageName.toLowerCase()}`
      ? true
      : loca === `/${pageName.toLowerCase()}/`
      ? true
      : false

  function checkHome() {
    return (
      (pageName === "wie zijn wij?" && loca === "/") ||
      (pageName === "wie zijn wij?" && history[0] === "/")
    )
  }

  useEffect(() => {
    isOpen && history.splice(0, 1, loca)
  }, [isOpen, loca])

  return (
    <li className={linkStyle.listItem}>
      <Link
        style={{
          margin: "0em 0.5em",
          color: "black",
          display: "grid",
        }}
        to={to}
      >
        <p
          style={{
            gridRow: 1,
            gridColumn: 1,
            margin: "0px",
            marginBottom: "0.25em",
            color: "black",
            fontSize: "12px",
            fontWeight: "500",
            letterSpacing: "0.025em",
          }}
        >
          <span className="line">{pageName}</span>
        </p>

        {(`/${pageName.toLowerCase()}` === history[0] ||
          isOpen ||
          checkHome()) && (
          <motion.div
            initial={isOpen ? "closed" : "open"}
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            transition={{
              // delay: 0.5,
              type: "spring",
              // repeat: "3",
              // repeatType: "mirror",
              stiffness: 2000,
              damping: 150,
            }}
            style={{
              justifySelf:
                `/${pageName.toLowerCase()}` === history[0] ? "right" : "left",
              backgroundColor: "black",
              transformOrigin: "center",
            }}
          ></motion.div>
        )}

        {/* )} */}
        {/* </AnimatePresence> */}
      </Link>
    </li>
  )
}
