//deps
import React, { useState } from "react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

// styling
import headerMStyle from "./headerMobile.module.scss"

// pics
import menuIcon from "../Assets/menu/menu.svg"
import closeIcon from "../Assets/menu/close.svg"

export default function HeaderMobile() {
  const [menu, setmenu] = useState(false)

  return (
    <div
      style={{
        // border: "solid 3px blue",
        backgroundColor: "white",
        height: "10vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          // border: "solid red 3px",
          zIndex: "3",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "16vw auto 16vw",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img
          src={menu ? closeIcon : menuIcon}
          style={{
            width: menu ? "1em" : "1.25em",
            gridColumn: "1",
          }}
          onTouchEnd={() => {
            setmenu(!menu)
          }}
          alt="menu"
        ></img>
        <div
          style={{
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "1.05em",
              gridColumn: "2",
              // backgroundColor: "blue",

              zIndex: "10",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            BUSINESS RESILIENCE
            <br />
            CONSULTANCY GROUP
          </h1>
        </div>
      </div>

      <AnimatePresence>
        {menu && (
          <motion.nav
            className={headerMStyle.menu}
            initial={{ marginTop: "-90vh" }}
            animate={{ marginTop: "10vh" }}
            exit={{ marginTop: "100vh" }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
          >
            <Link to="/">WIE ZIJN WIJ?</Link>
            {/* <Link to="/partners">PARTNERS</Link> */}
            <Link to="/werkwijze">WERKWIJZE</Link>
            <Link to="/gedragscode">GEDRAGSCODE</Link>
            <Link to="/contact">CONTACT</Link>
            <Link to="/blog">BLOG</Link>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  )
}
