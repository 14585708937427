// deps
import React, { useState } from "react"
import { motion, useViewportScroll } from "framer-motion"
import { useMediaQuery } from "react-responsive"

// styling
import headerStyle from "./header.module.scss"

// components
import { Link } from "gatsby"
import ListLink from "./listLink"
import HeaderMobile from "./headerMobile"

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 })
  return isMobile ? children : null
}
const Desktop = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 1000 })
  return isMobile ? children : null
}

export default function Header({ loc }) {
  let { scrollYProgress } = useViewportScroll(5)

  const [height, setheight] = useState(0)
  scrollYProgress.onChange(x => setheight(x))

  return (
    <div
      style={{ position: "sticky", top: "0px", zIndex: "10" }}
    >
      <Mobile>
        <HeaderMobile loc={loc}></HeaderMobile>
      </Mobile>
      <Desktop>
        <motion.header
          initial={{
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
          animate={{
            paddingTop: height > 0.05 ? "0.5em " : "2em",
            paddingBottom: height > 0.05 ? "0.5em " : "2em",
          }}
          transition={{
            type: "tween",
            duration: 0.4,
          }}
        >
          <Link to="/" className={headerStyle.logoType}>
            <h1>BUSINESS RESILIENCE CONSULTANCY GROUP</h1>
          </Link>
          <ul className={headerStyle.menu}>
            <ListLink loc={loc} to="/" pageName="wie zijn wij?"></ListLink>
            {/* <ListLink loc={loc} to="/partners" pageName="partners"></ListLink> */}
            <ListLink loc={loc} to="/werkwijze" pageName="werkwijze"></ListLink>
            <ListLink
              loc={loc}
              to="/gedragscode"
              pageName="Gedragscode"
            ></ListLink>
            <ListLink loc={loc} to="/contact" pageName="contact"></ListLink>
            <ListLink loc={loc} to="/blog" pageName="blog"></ListLink>
          </ul>
        </motion.header>
      </Desktop>
    </div>
  )
}
