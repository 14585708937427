import React, { useEffect, useState } from "react"
import layoutStyle from "./layout.module.scss"

import WhiteForm from "../components/whiteForm"
import Header from "../components/header"
import Footer from "../components/footer"

import useReactPath from "../hooks/useReactPath"
import WhiteFormMobile from "../components/whiteFormMobile"

const background = {
  img: {
    background: `url("https://source.unsplash.com/random/1920x1080")`,
    backgroundSize: "cover",
  },
  color: {
    // background: "lightcoral",
    backgroundSize: "cover",
  },
}

export default function Layout({ children, loc, bg }) {
  // const path = useReactPath()
  const [isMobile, setisMobile] = useState(false)
  useEffect(() => {
    // loc = path
    // console.log(`path is ${path.pathname}`)
    window.innerWidth < "500" && setisMobile(true)
  }, [])
  return (
    <div
      className={layoutStyle.layout}
      style={bg === "img" ? background.img : background.color}
    >
      <Header loc={loc}></Header>
      <div className={layoutStyle.formWrapper}>
        {console.log(loc.pathname)}
        {isMobile
          ? loc.pathname !== `/` && <WhiteFormMobile></WhiteFormMobile>
          : loc.pathname !== `/` && <WhiteForm></WhiteForm>}
      </div>
      <section className={layoutStyle.content}>{children}</section>
      <Footer loc={loc}></Footer>
    </div>
  )
}
